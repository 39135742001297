.location {
  &-area {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 25px 0;
    box-sizing: border-box;

    @media screen and (min-width:768px) {
      padding: 50px 0;
    }
  }

  &-desc {
    &-list {
      margin-top: 30px;
      text-align: left;

      @media screen and (min-width:768px) {
        column-count: 2;
      }
    }

    &-item {
      font-size: 14px;
      word-break: keep-all;
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:last-child {
        display: none;
      }

      @media screen and (min-width:768px) {
        &:last-child {
          display: block;
        }
      }

      strong {
        display: block;
        font-family: 'Playfair Display', serif;
        font-size: 18px;
        margin-bottom: 5px;

        @media screen and (min-width:768px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      .point {
        font-family: Pretendard-bold, sans-serif;
        margin-right: 5px;
      }
    }

    &-text {
      &:not(:last-child) {
        margin-bottom: 8px;

        @media screen and (min-width:768px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &-map {
    &-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }

    &-item {
      display: inline-block;
      width: 50px;
      height: 50px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    &-link {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1),0 4px 6px -4px rgba(0, 0, 0, .1);

      $icons: (naver, kakao, tmap, google);
      @each $icon in $icons {
        &.#{$icon} {
          background: url("../../assets/images/map_#{$icon}.png") no-repeat center center / contain;
        }
      }
    }
  }
}