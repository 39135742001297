.visual {
  &-image {
    &-area {
      height: 5000px;
      display: none;
      overflow: hidden;
      position: relative;

      @media screen and (min-width:1200px) {
        display: block;
      }

      &.fixed {
        position: relative;

        .scroll-btn {
          display: block;
        }

        img {
          position: fixed;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .scroll-btn {
        display: none;
        position: fixed;
        left: 50%;
        top: calc(100vh - 80px);
        transform: translateX(-50%);
        text-align: center;

        &-link {
          display: inline-block;
          letter-spacing: 2px;
        }

        &-text {
          font-size: 14px;
          font-weight: normal;
          color: #000;
          font-family: "Inter", "Pretendard-Regular", sans-serif;
        }

        .mouse {
          position: relative;
          display: block;
          width: 25px;
          height: 40px;
          box-sizing: border-box;
          border: 2px solid #000;
          border-radius: 23px;

          span {
            position: absolute;
            display: block;
            top: 29%;
            left: 50%;
            width: 8px;
            height: 8px;
            margin: -4px 0 0 -4px;
            background: #000;
            border-radius: 50%;
            animation: ani-mouse 2.5s linear infinite;
          }
        }
      }
    }
  }

  &-area {
    width: 100%;
    height: 100vmax;
    background: url("../../assets/images/main-visual.jpg") no-repeat center center / cover;
    position: relative;

    @media screen and (min-width:768px) {
      height: 100vh;
    }
  }

  &-text-area {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 80%;

    @media screen and (min-width:768px) {
      top: auto;
      bottom: 10%;
      width: auto;
      background-color: rgba(255, 255, 255, .4);
      padding: 25px 40px;
    }
  }

  &-title {
    font-size: 24px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    display: block;
    margin-bottom: 5px;

    @media screen and (min-width:768px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }

  &-sub-title {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-size: 18px;
    position: relative;
    margin-bottom: 30px;

    @media screen and (min-width:768px) {
      font-size: 24px;

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 20px;
        background-color: #222;
        content: '';
      }

      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 20px;
        background-color: #222;
        content: '';
      }
    }
  }

  &-wedding-info {
    &-date {
      display: block;
      margin-bottom: 3px;
    }

    &-place {
      display: block;
    }
  }
}

// 마우스 애니메이션
@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }

  15% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 0;
    top: 29%;
  }
}