.couple {
  &-text-area {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 0 25px;
    text-align: center;
    font-family: 'Cafe24Oneprettynight', serif;
    line-height: 1.5;

    @media screen and (min-width:768px) {
      padding: 0 0 50px;
      font-size: 20px;
    }
  }

  &-area {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 25px 0;

    @media screen and (min-width:768px) {
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }

  &-person-area:not(:last-child) {
    margin-bottom: 50px;

    @media screen and (min-width:768px) {
      margin-bottom: 0;
    }
  }

  &-parents {
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }

  &-name {
    display: block;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: Pretendard-bold, sans-serif;

    @media screen and (min-width:768px) {
      font-size: 24px;
    }
  }

  &-image {
    width: 100%;
    vertical-align: top;
  }

  &-video {
    &-container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 50px 0;

      @media screen and (min-width:768px) {
        padding: 100px 0 0;
      }
    }
  }
}