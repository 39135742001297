.gallery {
  &-area {
    padding: 25px 0;

    @media screen and (min-width:768px) {
      padding: 50px 0;
    }
  }

  &-list {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    :global .swiper-wrapper {
      flex-wrap: wrap;
    }
  }

  &-item {
    overflow: hidden;
    width: 33% !important;
    padding-top: 33%;
    cursor: pointer;

    @media screen and (min-width:1200px) {
      width: 400px !important;
      height: 400px;
      padding-top: 0;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;

    @media screen and (max-width:1200px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width:1200px) {
      transition: all .2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &-slide {
    &-container {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      display: none;

      &.active {
        display: block;
      }

      :global .swiper-button-prev,
      :global .swiper-button-next {
        width: 35px;
        height: 35px;
        padding: 0;

        &::before {
          @media screen and (min-width:576px) {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            content: '';
          }
        }

        &::after {
          display: none;
        }
      }

      :global .swiper-button-prev::before {
        @media screen and (min-width:576px) {
          background: rgba(0, 0, 0, .2)  url("../../assets/icons/arrow-left.svg") no-repeat center center / 23px 23px;
        }
      }

      :global .swiper-button-next::before {
        @media screen and (min-width:576px) {
          background: rgba(0, 0, 0, .2)  url("../../assets/icons/arrow-right.svg") no-repeat center center / 23px 23px;
        }
      }
    }

    &-dimmed {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .4);
    }

    &-close-button {
      position: absolute;
      top: calc(50% - 67vw);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, .4) url("../../assets/icons/close.svg") no-repeat center center / 18px 18px;
      z-index: 1000;
      cursor: pointer;

      @media screen and (min-width:576px) {
        top: calc(50% - 335px);
        left: calc(50% + 275px);
      }
    }

    &-list {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80vw;

      @media screen and (min-width:576px) {
        width: 500px;
      }
    }

    &-item {
      overflow: hidden;
      width: 80vw;
      height: 120vw;

      @media screen and (min-width:576px) {
        width: 500px;
        height: 700px;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}