.guestbook {
  &-area {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 25px 0 50px;

    @media screen and (min-width:768px) {
      padding: 50px 0;
    }
  }

  &-form {
    text-align: center;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width:768px) {
      width: 500px;
    }
  }

  &-name,
  &-textarea {
    border: 1px solid #222;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 6px;
    font-size: 14px;
  }

  &-name {
    margin-bottom: 10px;
    width: 100%;
  }

  &-textarea {
    resize: none;
    font-family: inherit;
    padding: 10px;
    margin: 0 0 10px 0;
    width: 100%;
    height: 100px;
  }

  &-submit-button {
    width: 100%;
    height: 50px;
    background-color: #222;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    display: block;
    transition: background-color .2s;

    &:hover {
      background-color: #000;
    }
  }

  &-no-result {
    display: block;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 6px;
    background-color: #efefef;
    text-align: center;
    width: 100%;
    margin: 30px auto 0;

    @media screen and (min-width:768px) {
      max-width: 500px;
    }
  }

  &-list {
    width: 100%;
    margin: 30px auto 0;

    @media screen and (min-width:768px) {
      max-width: 500px;
    }
  }

  &-item {
    display: block;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 6px;
    background-color: #efefef;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &-details-container {
    text-align: right;
    margin-top: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }

  &-writer,
  &-date {
    display: block;
    font-size: 12px;
  }
}