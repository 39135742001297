html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'Pretendard-Regular', sans-serif;
}

p {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
}